import Swiper from "swiper/bundle";

const [d] = [document];

export class worksBlock {

  constructor () {
    this.render();
  }

  render () {
    const getArticles = this.fetchArticles({
      content: 'works',
      post_per_page: 10
    });
    const getCategories = this.fetchCategories('works');
    const dispTags = tags => {
      tags = tags.map(t => `<li class="tag">#${t}</li>`);
      return `<ul class="tag_wrap">${tags.join('')}</ul>`;
    };
    const appendSlide = (item,slider) => {
      slider.appendSlide([`
        <div class="swiper-slide">
          <a href="${item.href}">
            <figure>
              <img src="${item.thumb}" alt="${item.title}">
            </figure>
            ${(item.category.name)? `<p class="cate">${item.category.name}</p>`: ''}
            <div class="text_wrap">
              <p class="ttl">${item.title}</p>
              ${(item.keywords)? dispTags(item.keywords):''}
            </div>
          </a>
        </div>
      `]);
    }
    const slider = new Swiper('.works_slide', {
      grabCursor: true,
      speed: 400,
      slidesPerView: 1.2,
      spaceBetween: 30,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.works-next',
        prevEl: '.works-prev',
      },
      pagination: {
        el: '.works-pagination',
        type: 'progressbar',
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 'auto',
          spaceBetween: 38,
        }
      },
    });
    slider.removeAllSlides();
    Promise.all([getArticles,getCategories]).then( ([articles,cats]) => {
      d.querySelector('.works_slide .swiper-wrapper').innerHTML = '';
      articles.map( article => {
        article.category = cats.find( v => v.id == article.category ) || {id: '0', href: '/works/?mode=list&cat=0', name: 'その他', turn: 99};
        appendSlide(article,slider);
      });
      slider.slideToLoop(0);
    });
  }

  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}