import RWD002 from "../class/rwd002";
import customDrawerNav from "./drawerNav";
import customFormConfirm from "./formConfirm";
// import FirstViewEndObserver from "./FirstViewEndObserver";
import customSubNav from "./subNav";
import LazyDisp from "../class/lazydisp";

const [ d, mq ] = [document, window.matchMedia( "(max-width: 899px)" ) ];

export default class CUSTOMIZE extends RWD002 {

  constructor(opt) {
    opt = Object.assign({
      // fixNavを指定すると、スクロール時に固定するクラスを付与する
      fixNav : '[data-role="fixNav"]',
    },opt);

    // 上位クラスのコンストラクタ呼び出し
    super(opt);

    // カスタム用drawerNavのインスタンス化
    this.drawerNav = new customDrawerNav(this.opt.drawerNav);

    // カスタム用サブナビ登録クラスのインスタンス化
    this.Subnav = new customSubNav(this.opt);

    // コメントフォーム用確認画面モーダルのインスタンス化
    this.formConfirm = new customFormConfirm(this.opt.formConfirm);

    this.customInit();
  }

  customInit() {

    // イベント一覧
    d.addEventListener('rwd002.beforeInit', () => false );
    d.addEventListener('rwd002.beforeDomready', () => false );
    d.addEventListener('rwd002.beforeWindowload', () => false );
    d.addEventListener('rwd002.zip2addr', () => false );
    d.addEventListener('drawerNav.beforeInit', () => false );
    d.addEventListener('collapseNav.beforeInit', () => false );
    d.addEventListener('Scroll.beforeInit', () => false );
    d.addEventListener('Scroll.scrollUp', () => false );
    d.addEventListener('Scroll.scrollDown', () => false );
    d.addEventListener('Lazydisp.beforeInit', () => false );

    // テンプレートインスタンスのプロミス
    this.promises.init.then( () => false );
    this.promises.domReady.then( () => false );
    this.promises.windowLoad.then( () => false );

    // テンプレートインスタンスの全非同期メソッドの完了時に実行
    this.promiseAll().then( () => false );

    // サブナビ登録サンプル
    d.addEventListener('rwd002.beforeDomready', () => {
      this.customSubnav();
      if( !mq.matches ) {
        const fixNav = d.querySelector('.rwd002-fixNav');
        // new FirstViewEndObserver(fixNav);
      }
    });

    $(() => {
      // htmlデータ読み込み
      $('[data-load-html]').loadHtml();

      // カレントメニュー
      $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});

      Promise.all([window.load['loadHtml']]).then(() => this.hashscroll());
      Promise.all([window.load['loadHtml']]).then( () => new LazyDisp('[data-lazydisp]'));
    });
  }

  // サブメニュー追加サンプル(beforeDomreadyに登録して使用)
  customSubnav () {
    /**
     * サブナビ追加メソッド(グローバルナビとフッター)
     * ヘッダー・フッター内の[data-key]属性値をもつ要素すべてに対して、サブナビを追加する
     * 第一引数にセレクタを指定(rwd002.opt.gnav / rwd002.opt.fnav )
     * 第一引数にコンテンツ番号を指定(li[data-key])
     * アイテムの出力HTMLは"subnavItemTemplate"によって出力される
     * 第二引数の個別アイテムにHTMLを指定すると、デフォルトのHTMLを上書きする
     * 親要素liに[data-collapse="true"]を指定すると、サブナビを開閉するボタンを追加する
     */

    // header gnav用サブナビ追加
    this.Subnav.add(this.opt.gnav, 'content2', [
      // { label: '事業内容', href: '#'},
      // { label: '治具製作・工具　製造', href: '#'},
      // { label: '専用機械・工具　製作', href: '#'},
      // { label: '密部品　製作', href: '#'},

      { html: `
        <li class="subnav_ttl pc">
          <a href="/business/">
            <strong>Business</strong>
            <span>事業内容</span>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item cont_ttl">
          <a href="/business/" class="${this.Subnav.opt.subnavClass}-item__link">事業内容</a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/business/?mode=detail&article=1" class="${this.Subnav.opt.subnavClass}-item__link">治具製作・工具　製造</a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/business/?mode=detail&article=2" class="${this.Subnav.opt.subnavClass}-item__link">専用機械・工具　製作</a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/business/?mode=detail&article=3" class="${this.Subnav.opt.subnavClass}-item__link">精密部品　製作</a>
        </li>`
      }
    ]);

    this.Subnav.add(this.opt.gnav, 'content3', [
      { html: `
        <li class="subnav_ttl pc">
          <a href="/company/">
            <strong>Company</strong>
            <span>会社案内</span>
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/company/" class="${this.Subnav.opt.subnavClass}-item__link">会社案内</a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/company/?mode=detail&article=2" class="${this.Subnav.opt.subnavClass}-item__link">設備紹介</a>
        </li>`
      }
    ]);

    // this.Subnav.add(this.opt.gnav, 'content3', [
    //   { html: `
    //     <li class="subnav_ttl pc">
    //       <strong>Contact</strong>
    //       <span>お問い合わせ</span>
    //     </li>`
    //   },
    //   { html: `
    //     <li class="${this.Subnav.opt.subnavClass}-item">
    //       <a href="#" class="${this.Subnav.opt.subnavClass}-item__link">お問い合わせ</a>
    //     </li>`
    //   },
    //   { html: `
    //     <li class="${this.Subnav.opt.subnavClass}-item">
    //       <a href="#" class="${this.Subnav.opt.subnavClass}-item__link">リクルートエントリー</a>
    //     </li>`
    //   }
    // ]);

    // footer nav用サブナビ追加
    this.Subnav.add(this.opt.fnav, 'content1', [
      { label: 'サブメニュー01', href: '#'},
      { label: 'サブメニュー02', href: '#'},
      { label: 'サブメニュー03', href: '#'},
    ]);
  }

  loading() {
    d.documentElement.classList.add( this.opt.loadingClass );
    d.documentElement.classList.add( 'is-pageload-available' );
    d.addEventListener('DOMContentLoaded', () => {
      d.documentElement.classList.remove( this.opt.loadingClass );
      d.documentElement.style.opacity = '';
    });
  }

}

$('#menuBtn').on('click', function(){
  $(this).toggleClass('open');
  $('.large_menu').toggleClass('open');
});

$('.slideTrigger').on('click', function(){
  $(this).toggleClass('open');
  $(this).next('.sub_list').slideToggle();
});

$(window).on('load', function () {
  let winH = $(window).height();
  $(window).on('scroll', function () {
    let t = $(this).scrollTop();
    let stopPt = $('footer .main_wrap').offset().top;
    let scrollPt = t + winH - 220;
    if (t > winH) {
      $('.pagetop').addClass('active');
    } else {
      $('.pagetop').removeClass('active');
    }
    if (scrollPt > stopPt){
      $('.pagetop').addClass('stop');
    } else {
      $('.pagetop').removeClass('stop');
    }
  });
  
  //カーソル追従
  const follower = document.getElementById('follower'); 
  //aタグにホバー中かどうかの判別フラグ
  let hovFlag = false;
  //マウスに追従させる処理 （リンクに吸い付いてる時は除外する）
  document.addEventListener('mousemove', function (e) {
    if (!hovFlag) {
      follower.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';
    }
  });
  //リンクへ吸い付く処理
  const linkElem = document.querySelectorAll('a:not(.no_stick_)');
  for (let i = 0; i < linkElem.length; i++) {
    linkElem[i].addEventListener('mouseover', function (e) {
      hovFlag = true;
      follower.classList.add('hov_');
      let rect = e.target.getBoundingClientRect();
      let posX = rect.left + (rect.width / 2);
      let posY = rect.top + (rect.height / 2);
      follower.style.transform = 'translate(' + posX + 'px, ' + posY + 'px)';
    });
    
    linkElem[i].addEventListener('mouseout', function (e) {
      hovFlag = false;
      follower.classList.remove('hov_');
    });
  }
});


window.WebFontConfig = {
  google: {
    families: ['Roboto:400', 'Barlow:400,600,700']
  },
  active: function () {
    sessionStorage.fonts = true;
  }
};

(function () {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();