import GET_ARTICLES from "../class/getArticles";
import { worksBlock } from "../class/works-block";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
  new worksBlock;
  let Mv = document.querySelector('.mainvisual');
  window.addEventListener('load', function() {
    Mv.classList.add('loaded');
  });
};

class BLOCKS {
  constructor() {
    this.news();
  }
  news () {
    // mainvisual news
    const news_ticker = new GET_ARTICLES('.mainvisual .news_detail[data-plugin="getArticles"]');
    news_ticker.makeItem = (item,content) => `
      <a href="${item.href}">
        <span class="date">${item.date}</span>
        <span class="ttl">${item.title}</span>
      </a>`;
    // news_ticker.makeItem = (item) => console.log(item);
    news_ticker.render();

    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl"><span>${item.title}</span></p>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();

    // カテゴリー
    const getContentData = Promise.all([
      getArticle.fetchArticles({
        content: 'news',
        post_per_page: 1,
        category_in: 0
      }),
      getArticle.fetchCategories('news'),
    ]).then( res => Promise.all(res.map(r=>r.json())) );

    let categoryContainer = d.querySelector('.block-news .tag_list[data-plugin="getCategories"]');
    getContentData.then( ([noCatArticles,cats]) => {
      categoryContainer.innerHTML = '';
      if( noCatArticles && noCatArticles.length > 0){
        cats.push({id:'0',name:'その他',href:`/news/?mode=list&cat=0`});
      }
      cats.forEach( item => {
        let itemHtml = `<li><a href="${item.href}">${item.name}</a></li>`;
        categoryContainer.insertAdjacentHTML('beforeend', itemHtml);
      });
    });
  }
}


const jqInit = () => false;

const onload = () => {
  $(window).on('scroll', function(){
    let scroll = $(this).scrollTop();
    if(scroll > 200){
      $('header').addClass('scroll');
    } else {
      $('header').removeClass('scroll');
    }
  });
}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
  $(window).on('load', onload);
}